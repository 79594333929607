var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bg-[#f2f2f2] w-100% w-100vw h-100vh" }, [
    _c(
      "div",
      [
        _c(
          "my-nav",
          {
            attrs: { title: "转发设备管理", "self-back": true },
            on: { back: _vm.goBack },
          },
          [
            _c("div", {
              staticClass: "icon-code",
              attrs: { slot: "right" },
              on: { click: _vm.searchCode },
              slot: "right",
            }),
          ]
        ),
        _c(
          "van-form",
          { staticClass: "form-container mt-8px" },
          [
            _c("MyFieldOfEquipmentSelect", {
              attrs: {
                projectCode: _vm.CommID,
                address: _vm.form.address,
                addressId: _vm.form.addressId,
                categoryCode: _vm.form.categoryCode,
                reallyType: _vm.form.reallyType,
              },
              on: {
                confirm: _vm.getSelectConfirm,
                "update:projectCode": function ($event) {
                  _vm.CommID = $event
                },
                "update:project-code": function ($event) {
                  _vm.CommID = $event
                },
                "update:address": function ($event) {
                  return _vm.$set(_vm.form, "address", $event)
                },
                "update:addressId": function ($event) {
                  return _vm.$set(_vm.form, "addressId", $event)
                },
                "update:address-id": function ($event) {
                  return _vm.$set(_vm.form, "addressId", $event)
                },
                "update:categoryCode": function ($event) {
                  return _vm.$set(_vm.form, "categoryCode", $event)
                },
                "update:category-code": function ($event) {
                  return _vm.$set(_vm.form, "categoryCode", $event)
                },
                "update:reallyType": function ($event) {
                  return _vm.$set(_vm.form, "reallyType", $event)
                },
                "update:really-type": function ($event) {
                  return _vm.$set(_vm.form, "reallyType", $event)
                },
              },
            }),
            _c("div", { staticClass: "mt-8px" }),
            _c("MyFieldOfMalfunctionReasonSelect", {
              attrs: {
                "disabled-click": !_vm.form.address,
                "problem-list": _vm.form.problemList,
                required: true,
                categoryCode: _vm.form.categoryCode,
                reallyType: _vm.form.reallyType,
              },
              on: {
                confirm: _vm.malfunctionReasonSelectConfirm,
                "update:problemList": function ($event) {
                  return _vm.$set(_vm.form, "problemList", $event)
                },
                "update:problem-list": function ($event) {
                  return _vm.$set(_vm.form, "problemList", $event)
                },
              },
            }),
            _vm.form.problemList.length > 0
              ? _c(
                  "div",
                  { staticClass: "problem-list" },
                  _vm._l(this.form.problemList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" })
                  }),
                  0
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "btn-container" },
      [
        _c(
          "van-button",
          {
            staticClass: "w-343px h-48px bg-[#3366fe] text-18px",
            attrs: { type: "info" },
            on: { click: _vm.onSubmit },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }