<template>
  <div class="bg-[#f2f2f2] w-100% w-100vw h-100vh">
    <div>
      <my-nav title='转发设备管理' :self-back="true" @back="goBack">
        <div slot="right" class="icon-code" @click="searchCode"></div>
      </my-nav>
      <van-form class="form-container mt-8px">
        <MyFieldOfEquipmentSelect @confirm="getSelectConfirm" :projectCode.sync="CommID" :address.sync="form.address" :addressId.sync="form.addressId" :categoryCode.sync ="form.categoryCode" :reallyType.sync="form.reallyType"/>
        <div class="mt-8px"></div>
        <MyFieldOfMalfunctionReasonSelect @confirm="malfunctionReasonSelectConfirm" :disabled-click="!form.address" :problem-list.sync="form.problemList" :required="true" :categoryCode ="form.categoryCode" :reallyType="form.reallyType"/>
        <div class="problem-list" v-if="form.problemList.length > 0">
          <div
            class="item"
            v-for="(item, index) of this.form.problemList"
            :key="index"
          >
          </div>
        </div>
      </van-form>
    </div>
    <div class="btn-container">
        <van-button class="w-343px h-48px bg-[#3366fe] text-18px " @click="onSubmit" type="info">提交</van-button>
      </div>
  </div>
</template>

<script>

import mobile from '@/utils/mobile'
import moment from 'moment'
import api from '@/api'
import MyFieldOfEquipmentSelect from '@/components/MyFieldOfEquipmentSelect/index'
import MyFieldOfMalfunctionReasonSelect from '@/components/MyFieldOfMalfunctionReasonSelect/index'

export default {
  components: {
    MyFieldOfMalfunctionReasonSelect,
    MyFieldOfEquipmentSelect
  },
  name: 'ReportForward', // 报事转发
  props: {},
  data () {
    return {
      show: true,
      dataSource: '天问客服报事', // 工单来源
      orderType: 3, // 工单类型
      projectCode: '',
      projectName: '',
      form: {
        reallyType: undefined,
        categoryCode: undefined,
        address: '', // 所在位置
        addressId: '', // 所在位置编号
        addressType: undefined,
        problemList: [],
        attachmentList: [], // name type url
        createTime: null,
        remark: ''
      },
      twIncidentId: '', // 天问报事ID
      twIncidentNo: '', //
      CommID: undefined, // APP转发工单传过来的projectCode
      backAPP: true
    }
  },
  created () {},
  mounted () {
    this.CommID = this.$route.query.CommID
    let imagesList = this.$route.query.attachmentList
    if (imagesList && typeof imagesList === 'string') {
      if (typeof imagesList === 'string') {
        this.form.attachmentList = imagesList.split(',').reduce((arr, item) => {
          let imageName = item.split('/')
          if (imageName) {
            arr.push({ name: imageName[imageName.length - 1], type: 'image', url: item })
          }
          return arr
        }, [])
      } else if (imagesList instanceof Array) {
        this.form.attachmentList = imagesList
      }
    }
    this.form.attachmentList.forEach((item, index) => {
      if (!item.name) {
        this.form.attachmentList.splice(index, 1)
      }
    })
    console.log('this.form.attachmentList: ', this.form.attachmentList)
    console.log('this.$route.query.twIncidentId: ', this.$route.query.twIncidentId)
    console.log('this.$route.query.remark: ', this.$route.query.remark)
    console.log('this.$route.query.IncidentNum: ', this.$route.query.IncidentNum)
    console.log('this.$route.query.CommID: ', this.$route.query.CommID)
    console.log('this.$route.query.attachmentList: ', this.$route.query.attachmentList)
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'OrderDetail') {
        vm.backAPP = false
        vm.dataSource = '报事工单'
      }
    })
  },
  watch: {
    'form.address' (val) {
      this.form.problemList = []
    }
  },
  methods: {
    goBack () {
      if (this.backAPP) {
        mobile.backWeb()
      } else {
        this.$router.back()
      }
    },
    // 选择设施设备
    getSelectConfirm (data, equipType) {
      console.log('data: ', data)
      if (data[0].type === '3') {
        this.form.addressType = '3'
      } else {
        this.form.addressType = '2'
      }
      this.projectCode = data[0].projectCode
      this.projectName = data[0].projectName
    },
    // 选择主要部件故障原因
    malfunctionReasonSelectConfirm (problemList) {
      this.form.problemList = problemList
    },
    onSubmit () {
      if (!this.form.address) {
        this.$toast('请选择设施设备')
        return
      }
      if (this.form.problemList.length === 0) {
        this.$toast('请选择主要部件故障原因')
        return
      }
      this.twIncidentId = this.$route.query.twIncidentId
      this.twIncidentNo = this.$route.query.IncidentNum
      this.form.remark = this.$route.query.remark
      this.$toast.loading({
        message: '提交中...',
        forbidClick: true
      })
      this.form.createTime = moment().format('YYYY-MM-DD HH:mm:ss')
      this.$http
        .post(api.pinkongProblemCreate, {
          dataSource: this.dataSource, // 设备来源
          orderType: this.orderType,
          projectCode: this.projectCode,
          projectName: this.projectName,
          problemRecordList: [this.form],
          twIncidentId: this.twIncidentId,
          twIncidentNo: this.twIncidentNo
        })
        .then((res) => {
          this.$toast.clear()
          this.$toast.success('转发设施设备成功')
          const _this = this
          setTimeout(() => {
            _this.goBack()
          }, 1000)
        })
    },
    searchCode () { // 扫码
      this.$scanCod.jump(this.back, this.CommID)
    },
    back (res) { // 回调
      console.log('扫码结果res: ', res)
      if (!res?.length) {
        this.$toast('项目与二维码不匹配')
        return
      }
      this.projectCode = res[0].projectCode
      this.projectName = res[0].projectName
      this.form.address = `${res[0].name}/${res[0].pointTreePointResps[0].name}`
      this.form.categoryCode = res[0].pointTreePointResps[0].categoryCode
      this.form.addressId = res[0].pointTreePointResps[0].code
      this.form.reallyType = res[0].pointTreePointResps[0].type
      this.form.addressType = res[0].pointTreePointResps[0].type
    }
  }
}

</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
